@import "../../styles/_chunk";

.payment-details-container {
  .payment-options {
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-right: 2rem;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 3rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 4rem;
    }

    .secure-payment-banner {
      display: inline-flex;
      align-items: center;
      border: 1px solid $success-core;
      border-radius: $border-radius-sm;
      margin-bottom: 1rem;

      .icon-container {
        background-color: $success-core;
        color: $white;
        padding: 0.125rem 0.5rem;
      }

      .text-container {
        padding: 0.125rem 0.5rem;
        font-weight: 400;
      }
    }

    .payment-option-container {
      background-color: $card-background-dark;
      margin-top: 4px;

      &.payment-option-wechatpayQR {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        box-shadow: map-get($light-theme, card-box-shadow);
        margin-top: 8px;
      }

      &:first-of-type {
        border-top: 0;
      }

      &.Card {
        .payment-option-collapse {
          min-height: 370px;
        }
      }

      &.new-design {
        .payment-option {
          padding: 0;
        }

        .card-fields-container {
          .payment-option-collapse {
            .saved-card-container {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-end;
              margin-left: -0.5rem;
              margin-right: -0.5rem;

              @include media-breakpoint-up(md) {
                margin-left: -0.75rem;
                margin-right: -0.75rem;
              }

              @include media-breakpoint-up(xl) {
                margin-left: -0.875rem;
                margin-right: -0.875rem;
              }

              @include media-breakpoint-up(xxl) {
                margin-left: -1rem;
                margin-right: -1rem;
              }

              .card-details-expiry-date,
              .card-details-ccv,
              .remove-button-mobile,
              .payment-button,
              .remove-button-desktop {
                padding-left: 0.5rem;
                padding-right: 0.5rem;

                @include media-breakpoint-up(md) {
                  padding-left: 0.75rem;
                  padding-right: 0.75rem;
                }

                @include media-breakpoint-up(xl) {
                  padding-left: 0.875rem;
                  padding-right: 0.875rem;
                }

                @include media-breakpoint-up(xxl) {
                  padding-left: 1rem;
                  padding-right: 1rem;
                }
              }

              .card-details-expiry-date,
              .card-details-ccv,
              .payment-button {
                width: 100%;
                margin-bottom: 0.75rem;

                @include media-breakpoint-up(sm) {
                  width: 50%;
                }

                @include media-breakpoint-up(md) {
                  width: 33.333%;
                }

                @include media-breakpoint-up(lg) {
                  width: 50%;
                }

                @include media-breakpoint-up(xl) {
                  width: 33.333%;
                }
              }

              .remove-button-mobile {
                display: none;

                @include media-breakpoint-up(sm) {
                  display: block;
                  width: 50%;
                  margin-bottom: 0.75rem;
                }

                @include media-breakpoint-up(md) {
                  display: none;
                }

                @include media-breakpoint-up(lg) {
                  display: block;
                  width: 50%;
                }

                @include media-breakpoint-up(xl) {
                  display: none;
                }
              }

              .remove-button-desktop {
                width: 100%;

                @include media-breakpoint-up(sm) {
                  display: none;
                }

                @include media-breakpoint-up(md) {
                  display: block;
                }

                @include media-breakpoint-up(lg) {
                  display: none;
                }

                @include media-breakpoint-up(xl) {
                  display: block;
                }
              }

              .card-details-expiry-date,
              .card-details-ccv {
                .card-label,
                p {
                  text-transform: uppercase;
                  color: $text-muted;
                  font-weight: 400;
                  margin-bottom: 0.375rem;
                  font-size: 1rem;
                }
              }

              .card-details-expiry-date {
                .card-label {
                  min-height: 30px;
                }

                .card-information {
                  @include media-breakpoint-up(sm) {
                    height: 38px;
                  }
                }
              }

              .card-details-ccv {
                label {
                  margin-bottom: 0;
                }

                .cvc-text-container {
                  display: flex;
                  align-items: center;
                  min-height: 30px;
                }

                .question-icon-container {
                  position: relative;
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.25rem;
                  margin-left: 0.5rem;
                }

                .icon-button {
                  padding: 0;
                  border: 0;
                  height: 16px;
                  font-size: 16px;
                  background-color: transparent;
                  display: flex;
                  color: $text-white;

                  @include theme(light) {
                    color: map-get($light-theme, font-color);
                  }

                  &:hover {
                    @include brand-property(color, brand-core);
                  }
                }

                iframe {
                  @include brand-property(border-color, brand-core, important);

                  border: 2px solid $primary-core !important;
                  width: 120px !important;
                }
              }

              .payment-button {
                display: flex;
                justify-content: flex-end;

                @include media-breakpoint-only(lg) {
                  justify-content: flex-start;
                }
              }

              &.apple-pay {
                .card-details-expiry-date {
                  @include media-breakpoint-up(md) {
                    width: 50%;
                  }

                  .card-information {
                    @include media-breakpoint-up(sm) {
                      height: auto;
                    }
                  }
                }

                .payment-button {
                  @include media-breakpoint-up(md) {
                    width: 50%;
                  }
                }

                .remove-button-mobile {
                  @include media-breakpoint-up(sm) {
                    display: none;
                  }
                }

                .remove-button-desktop {
                  @include media-breakpoint-up(sm) {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    .payment-option {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: 0.5rem 0;
      min-height: 64px;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        color: map-get($light-theme, font-color);
      }

      &:active:focus {
        box-shadow: none;
      }

      &:active {
        background-color: $card-background-dark;
        border: none;
        box-shadow: none;

        @include theme(light) {
          background-color: map-get($light-theme, card-background);
        }
      }

      .left-container {
        display: flex;
        align-items: center;
        padding: 15px;
        min-width: 180px;

        @include media-breakpoint-up(xl) {
          padding: 20px;
        }
      }

      .right-container {
        margin-right: 15px;
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(md) {
          margin-right: 20px;
        }

        .payment-image-container {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
        }

        .payment-image {
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 49px;
          height: 30px;
          padding: 0 1px;

          @include media-breakpoint-up(xl) {
            width: 56px;
            height: 34px;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }

      .option-circle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid $white;
        margin-right: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        @include theme(light) {
          border-color: $grey-4;
        }

        &.selected {
          @include brand-property(background-color, brand-core);
          @include brand-property(border-color, brand-core, important);
        }

        &.disabled {
          border-color: $error-core;
          cursor: not-allowed;
        }

        .middle-circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $white;
        }
      }

      .payment-name-container {
        font-weight: 700;
        font-size: 15px;
        margin-right: 0.75rem;
        text-align: left;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }

        .payment-name {
          display: flex;
          flex-direction: column;

          @include media-breakpoint-up(sm) {
            flex-direction: row;
          }

          @include media-breakpoint-up(md) {
            margin-right: 1rem;
          }
        }
      }

      .payment-fee {
        font-size: 13px;
        color: $grey-4;
      }
    }

    .payment-option-expired {
      border: 1px solid $error-core;
    }

    .payment-option-collapse {
      padding: 15px;
      padding-top: 0;

      @include media-breakpoint-up(md) {
        padding: 20px;
        padding-top: 0;
      }

      &.padding-top {
        padding-top: 15px;

        @include media-breakpoint-up(md) {
          padding-top: 20px;
        }
      }

      .remember-tick-container {
        input {
          font-size: 20px;
          margin-right: 0.25rem;
        }
      }

      .card-fields-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        p {
          margin-bottom: 8px;
        }

        label {
          cursor: pointer;
          margin-bottom: 0;
        }

        .field-card {
          margin-bottom: 0.5rem;
        }

        .input-field {
          display: block;
        }

        .field-card-number {
          width: 100%;

          @include media-breakpoint-up(lg) {
            width: 80%;
          }
        }

        .field-card-name {
          width: 100%;

          @include media-breakpoint-up(lg) {
            width: 80%;
          }

          input {
            height: 38px;
            padding: 10px 14px;
            border-radius: 3px;
            background-color: $white !important;
            margin-top: -5px;
            margin-bottom: 15px;
            width: 100%;
            border: 1px solid map-get($light-theme, input-border-color);
            outline: 0;
            color: rgb(33, 33, 33);
            font-size: 15px;
            font-weight: 400;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
          }

          input::placeholder {
            color: rgb(216, 216, 216);
            font-weight: 300;
          }
        }

        .card-fields-second-row-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }

        .month-year-slash {
          font-weight: 700;
          font-size: 18px;
          width: 8%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          margin-bottom: 15px;
          height: 40px;

          @include media-breakpoint-up(md) {
            width: 5%;
          }
        }

        .field-card-exp-month {
          width: 40%;
          // margin-right: 1rem;

          @include media-breakpoint-up(sm) {
            width: 25%;
          }

          @include media-breakpoint-up(md) {
            width: 18%;
          }

          @include media-breakpoint-up(lg) {
            width: 20%;
          }

          @include media-breakpoint-up(xl) {
            width: 16%;
          }
        }

        .field-card-exp-year {
          width: 40%;
          margin-right: 1.5rem;

          @include media-breakpoint-up(sm) {
            width: 25%;
          }

          @include media-breakpoint-up(md) {
            width: 18%;
          }

          @include media-breakpoint-up(lg) {
            width: 20%;
            margin-right: 2rem;
          }

          @include media-breakpoint-up(xl) {
            width: 16%;
          }
        }

        .field-card-cvc {
          width: 40%;

          @include media-breakpoint-up(md) {
            width: 22%;
          }

          label {
            width: 100%;
          }

          .cvc-text-container {
            display: flex;
            justify-content: space-between;
          }

          .question-icon-container {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
          }

          .icon-button {
            padding: 0;
            border: 0;
            height: 16px;
            font-size: 16px;
            background-color: transparent;
            display: flex;
            color: $text-white;

            @include theme(light) {
              color: map-get($light-theme, font-color);
            }

            &:hover {
              @include brand-property(color, brand-core);
            }
          }
        }
      }
    }

    iframe {
      background-color: $white;
      font-weight: 400;
      height: 38px !important;
      padding: 0.375rem 0.75rem;
      line-height: 1.6;
      color: $text-black;
      border: 1px solid map-get($light-theme, input-border-color) !important;
      border-radius: 3px;
    }

    .adyen-checkout__paypal {
      iframe {
        border: 0 !important;
        height: 44px !important;
        padding: 0;
        border-radius: 0;
      }
    }

    .fieldHasError iframe,
    input.fieldHasError {
      border: 3px solid $error-core !important;
      border-radius: 0 !important;
    }
  }

  .order-button {
    text-transform: uppercase;
    font-weight: 700;
    min-height: 44px;
    background-color: $grey-2;
    border-color: $grey-2;
    color: $primary-button-text;

    &:hover,
    &:active {
      background-color: $grey-3;
      border-color: $grey-3;
      color: $primary-button-text;
    }
  }

  .ideal-buttons-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: $primary-button-text;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .complete-button-container {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    .paypal-checkout-btn {
      min-width: 200px;
    }

    .applepay-checkout-btn {
      [type=button] {
        -webkit-appearance: -apple-pay-button;
        border-radius: 4px;
      }
    }
  }

  .ideal-dropdown-button {
    min-width: 200px;
    background-color: $white;
    border-color: $white;
    color: $primary-button-text;
    font-weight: 400;
    height: 44px;

    &:hover,
    &:active {
      background-color: $grey-2;
      border-color: $grey-2;
      color: $primary-button-text;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }

  .complete-button {
    @include button-variant($primary-core, $primary-core);

    color: $primary-button-text;
    min-width: 200px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:hover,
    &:active {
      color: $primary-button-text;
    }
  }

  .paypal-button {
    background-color: $paypal-blue;
    border-color: $paypal-blue;
    color: $white;

    &:hover,
    &:active {
      background-color: darken($paypal-blue, 10%);
      border-color: darken($paypal-blue, 10%);
      color: $white;
    }
  }

  .qiwi-button {
    background-color: $qiwi-color;
    border-color: $qiwi-color;

    &:hover,
    &:active {
      background-color: darken($qiwi-color, 10%);
      border-color: darken($qiwi-color, 10%);
    }
  }

  .card-field-error {
    background-color: $error-core;
    color: $white;
    text-align: center;
    padding: 5px 8px;
    border-radius: 6px;
    position: relative;
    z-index: 1;
    width: initial;
    top: 2px;
    display: inline-block;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      left: 20px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $error-core transparent;
    }
  }

  .payment-image.paywithgoogle {
    width: 70px !important;
    height: auto !important;

    img {
      border: 0 !important;
    }
  }

  .gpay-button.pay {
    padding: 9px 24px !important;
  }

  .union-pay,
  .payment-name-fee {
    color: $grey-5;
    font-size: 13px;
  }

  .paypal-btn {
    background: $paypal-yellow;
    width: 180px !important;
    height: 40px;
    color: $black;
    font-size: 13px !important;
    font-weight: 300;
    border-radius: 5px;
    border: none;

    &:hover {
      background: #ebb247;
      color: $black !important;
    }

    img {
      width: 65px;
    }
  }
}

.cvc-message {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 220px;
  margin-bottom: 20px;
  margin-right: -103px;
  z-index: 500;

  @include media-breakpoint-up(sm) {
    margin-right: -133px;
    width: 280px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: -153px;
    width: 320px;
  }

  @include media-breakpoint-up(xl) {
    margin-right: -173px;
    width: 360px;
  }

  .text-container {
    position: relative;
    background-color: $white;
    color: $text-black;
    font-size: 14px;
    padding: 15px;
    border-radius: $border-radius;
  }

  .card-images {
    display: flex;
    justify-content: space-evenly;
    margin-top: 12px;

    img {
      max-width: 100%;
    }

    .credit-card-img,
    .amex-img {
      width: 40%;
      flex-shrink: 0;

      @include media-breakpoint-up(sm) {
        width: 30%;
      }

      @include media-breakpoint-up(md) {
        width: 35%;
      }

      @include media-breakpoint-up(lg) {
        width: 32%;
      }

      @include media-breakpoint-up(xl) {
        width: 30%;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 8px;
    padding-top: 3px;

    &:hover {
      cursor: pointer;
    }
  }

  .cvc-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $white;
    margin: 0 auto;
  }
}
